import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    ListsWidget4,
    ListsWidget5,
    TablesWidget10,
    MixedWidget8,
    MixedWidget5,
    MixedWidget3,
} from '../../../_metronic/partials/widgets'
import {

    Link
} from "react-router-dom";
import axios from "axios";

const DashboardPage = () => {
    useEffect(() => {
        // We have to show toolbar only for dashboard page
        document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
        return () => {
            document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
        }
    }, [])

    return (
        <>
        </>
    )
}
let dashboardData;
const API_URL = process.env.REACT_APP_API_BASE_URL

function getDashboardPageData() {
    axios
        .get(`${API_URL}/admin/getdashboarddata`)
        .then((response) => response.data).then((response) => {
        dashboardData = response.data;
    })
}

getDashboardPageData();

const DashboardWrapper = () => {
    const intl = useIntl()
    const [receiveEmiData, setReceiveEmiData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [buttonText, setButtonText] = useState('Search Finances');
    const getFinancesByAadhar = () => {
        setErrorMsg('');
        setButtonText('...');
        axios
            .get(`${API_URL}/admin/get-finances-by-aadhar/` + aadhar)
            .then((response) => response.data).then((response) => {
            setReceiveEmiData(response.data);
            if (!response.status)
                setErrorMsg(response.message);

            setButtonText('Search Finances');
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            {/*<DashboardPage />*/}
            <div className={'row my-5'}>
                <div className={'col-md-3'}>
                    <Link to="/view-all-users/users">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-success"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-people display-1 text-success"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.users}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Users</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/branch-management/branch">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-warning"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-share display-1 text-warning"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.branches}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Branches</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/product-management/products">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-back display-1 text-primary"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.products}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Products</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/finance-management/finances">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-success"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-bookmarks display-1 text-success"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.total_finances} / {dashboardData.current_finances}</h1>
                                    <p className={"mb-1 text-dark"}>Total / Current Finances</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
            <div className={'row my-5'}>
                <div className={'col-md-3'}>
                    <Link to="/financer-management/users">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-person-heart display-1 text-primary"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.financers}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Financers</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/distributor-management/users">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-danger"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-person-lines-fill display-1 text-danger"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.distributors}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Distributors</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/user-management/users">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-warning"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-people-fill display-1 text-warning"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.customers}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Customers</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-md-3'}>
                    <Link to="/employee-management/users">
                        <div className={"position-relative ps-6 pe-3 py-2 bg-white"}>
                            <div className={"position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"}></div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <i className={"bi bi-person-bounding-box display-1 text-primary"}></i>
                                </div>
                                <div className={"col text-center"}>
                                    <h1 className={"fs-3x"}>{dashboardData.employees}</h1>
                                    <p className={"mb-1 fs-2x text-dark"}>Employees</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <Link to={'/reports/print-emistatus/all'}>
                        <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                            <div className={'row p-5'}>
                                <div className={'col text-start text-white'}>
                                    Total EMIs
                                </div>
                                <div className={'col text-end text-white'}>
                                    {dashboardData.total_emis}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/reports/print-emistatus/paid'}>
                        <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                            <div className={'row p-5'}>
                                <div className={'col text-start text-white'}>
                                    Paid EMIs
                                </div>
                                <div className={'col text-end text-white'}>
                                    {dashboardData.paid_emis}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/reports/print-emistatus/unpaid'}>
                        <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                            <div className={'row p-5'}>
                                <div className={'col text-start text-white'}>
                                    Unpaid EMIs
                                </div>
                                <div className={'col text-end text-white'}>
                                    {dashboardData.unpaid_emis}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/reports/print-emistatus/pending'}>
                        <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                            <div className={'row p-5'}>
                                <div className={'col text-start text-white'}>
                                    Pending EMIs
                                </div>
                                <div className={'col text-end text-white'}>
                                    {dashboardData.pending_emis}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/reports/print-emistatus/upcoming'}>
                        <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                            <div className={'row p-5'}>
                                <div className={'col text-start text-white'}>
                                    Upcoming EMIs
                                </div>
                                <div className={'col text-end text-white'}>
                                    {dashboardData.upcoming_emis}
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Today's paid amount
                            </div>
                            <div className={'col text-end text-white'}>
                                {dashboardData.today_paid_amount}
                            </div>
                        </div>
                    </div>
                </div>


                <div className={'col'}>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Financers Invested Amount
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.financer_invested_amount}
                            </div>
                        </div>
                    </div>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Financers Returnable Amount
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.financer_returnable_amount}
                            </div>
                        </div>
                    </div>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Financers Recovered Amount
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.financer_recovered_amount}
                            </div>
                        </div>
                    </div>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Financers Balance Amount
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.financer_balance_amount}
                            </div>
                        </div>
                    </div>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Financers Profit
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.financcer_profit}
                            </div>
                        </div>
                    </div>
                    <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
                        <div className={'row p-5'}>
                            <div className={'col text-start text-white'}>
                                Company Profit
                            </div>
                            <div className={'col text-end text-white'}>
                                ₹{dashboardData.company_profit}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"card mt-9"}>
                <div className={"card-header bg-info pt-7"}>
                    <h4 className={"text-white"}>Receive EMI</h4>
                </div>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={'col-md-3'}>
                            {/* begin::Label */}
                            <label className='required fw-bold fs-6 mb-2'>Aadhar / Mobile No</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            <input
                                placeholder='Aadhar / Mobile No'
                                type='number'
                                min={100000000000}
                                max={999999999999}
                                step={1}
                                className={
                                    'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                                onBlur={(e) => setAadhar(e.target.value)}
                            />
                            {/* end::Input */}
                        </div>
                        <div className={"col-md-3"}>
                            <button className={"btn btn-info mt-9"} onClick={getFinancesByAadhar}>{buttonText}</button>
                        </div>
                    </div>
                    <p className={"text-danger"}>{errorMsg}</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Sr</th>
                            <th>Finance ID</th>
                            <th>Name</th>
                            <th>Product Name</th>
                            <th>Total EMIs</th>
                            <th>Paid EMIs</th>
                            <th>Unpaid EMIs</th>
                            <th>Pending EMIs</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        {receiveEmiData.length > 0 && receiveEmiData.map((emiData: any, k) => {
                            return <tr>
                                <td>{k + 1}</td>
                                <td>{emiData.unique_id}</td>
                                <td>{emiData.first_name}</td>
                                <td>{emiData.product_name}</td>
                                <td>{emiData.total_emis}</td>
                                <td>{emiData.paid_emis}</td>
                                <td>{emiData.total_emis - emiData.paid_emis}</td>
                                <td>{emiData.unpaid_emis}</td>
                                <td><Link to={'/emi-management/emis/' + emiData.id}>
                                    <button className={"btn btn-success"}>Receive EMI</button>
                                </Link></td>
                            </tr>
                        })}

                    </table>


                </div>
            </div>
        </>
    )
}

export {DashboardWrapper}
